import React from 'react'
import CaseStudiesSidebarRDM from './CaseStudiesSidebarRDM'
import details1 from '../../assets/images/projects/projects-details1.jpg'
import project2 from '../../assets/images/projects/project2.jpg'

const CaseStudiesDetailsContentRDM = () => {
    return (
        <section className="case-studies-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="case-studies-details-image">
                            <img src={details1} alt="about" />
                        </div>
                        <div className="case-studies-details-desc">
                            <span className="sub-title">Reference Data Management</span>
                            <h3>Reference Data Management: gather, govern, provision</h3>
                            <p>Having a centralized process of managing classifications and hierarchies across systems and business lines, it facilitates unified reporting and ensures integrity.</p>
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>Important Topics</h3>
                                        <ul>
                                            <li>Industry Classifications</li>
                                            <li>Custom Classifications</li>
                                            <li>Governance</li>
                                            <li>Automation</li>
                                            <li>Provisioning</li>
                                            <li>Versioning</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <p>In a complex organization the number of systems can be significant and each of these systems is working on a predefined List of Values. Imagine coding a simple "client's state" in various ways, like Active = A = ACT = 1 = true. Reference Data Management gathers and unifies these lists of values matching them and provides one single place to manage them all.</p>
                            <p>Each <b>industry</b> has their own standard list of values, which comes from regulatory or governmental platforms. A centralized RDM gathers these classifications or hierarchies once under one platform and makes them available for usage, avoiding individual loads for each system in the organization. Working with AbInitio it is easy to deliver graphs to access public/private web services or adapt to any technology necessary to load these data.</p>
                            <p>Having in one single place the reference data it is important to have a <b>governance</b> flow, especially on top of the custom ones. Any enhancement or change in the available values over a classification can be easily handled in one single place, but is mandatory to have approval and acceptance. This is why consumers of that data must agree and be able to react upon changes with appropriate consent.</p>
                            <p>In case we are dealing with Industry standards every list of values should support <b>automation</b> to ensure immediate update for all consumers, but not only in gathering the reference data, as Life Cycle Management requires propagation of both these Industry and Custom classifications to be delivered across the other environments (TEST, DEV, ...).</p>
                            <p>AbInitio delivers a standard out-of-the-box API to access metadata, and it supports an easy <b>consumption</b> of the reference data being performant on both reporting as operational systems.</p>
                            <h3>Challenges</h3>
                            <p><b>Performance</b> - One of the greatest challenges is the performance in accessing the reference data. A clear architecture ensures the timeline for consumption, where operational systems would decide and reconcile upon the time spent to daily usage against on-update synchronization.</p>
                            <h3>Results</h3>
                            <p>Defining the Data Quality Framework from the beginning based on the principles mentioned above we were able to introduce to clients information about their current quality, trend information about quality improvements over time, decision-making information to stop or proceed with processing or reporting, and even automation for fixing or improving the quality. Nevertheless, in our experience we realized that this kind of solution must be supported by other streams of a complex organization, like Modelers, Architects, and it always succeeded by being effective if we have a good Metadata Management next to it.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <CaseStudiesSidebarRDM />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CaseStudiesDetailsContentRDM